import React, { useEffect, useRef, useState } from "react"

import { motion, AnimatePresence } from "framer-motion"

import lottie from "lottie-web"

const Preloader = () => {
  // State
  const [preloader, setPreloader] = useState(true)

  // Refs
  const animationContainer = useRef(null)

  // Helpers
  useEffect(() => {
    document.querySelector("body")?.classList.add("overflow-hidden")

    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      autoplay: true,
      loop: false,
      path: "/preloader.json",
      rendererSettings: {
        progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
        hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
      },
    })

    setTimeout(() => {
      setPreloader(false)
      document.querySelector("body")?.classList.remove("overflow-hidden")
    }, 3000)
  }, [])

  return (
    <AnimatePresence>
      {preloader && (
        <motion.div
          variants={{ show: { opacity: 1 }, hide: { opacity: 0 } }}
          initial="show"
          animate="show"
          exit="hide"
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900"
        >
          <div
            className="lg:w-1/2 w-8/12 h-full"
            ref={animationContainer}
          ></div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Preloader
