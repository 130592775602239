import React from "react"

// Component imports
import Text from "../components/Text"

interface Props {
  children: string
}

const Heading = ({ children }: Props) => {
  return (
    <div className="relative">
      <Text
        tag="h2"
        styleTo="h2"
        className="relative z-10 block text-center text-red-500"
      >
        {children}
      </Text>
      <div className="md:absolute md:inset-0 md:flex md:flex-col md:items-center md:justify-center hidden">
        <p
          className="font-bebasNeue opacity-5 text-7xl tracking-12px uppercase"
          style={{ WebkitTextStroke: `1px white`, color: `transparent` }}
        >
          {children}
        </p>
      </div>
    </div>
  )
}

export default Heading
