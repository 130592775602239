import React from "react"

// Component imports
import Text from "./Text"
import Image from "gatsby-image"
import { Carousel } from "react-responsive-carousel"

interface Props {
  Icon: any
  name: string
  description: string
  features: string[]
  images: any[]
}

const BulletPoint = ({ children }: { children: string }) => (
  <div className="flex items-center space-x-3">
    <div className="w-7 h-1 bg-red-400 rounded-full select-none">&nbsp;</div>
    <Text className="text-sm">{children}</Text>
  </div>
)

const Companion = ({ Icon, name, description, features, images }: Props) => {
  return (
    <div className="xl:flex xl:items-start xl:space-y-0 xl:space-x-20 w-full space-y-6">
      <div className="xl:w-1/3 xl:space-y-8 space-y-6">
        <div className="flex items-center space-x-4">
          <div className="lg:w-12 lg:h-12 flex items-center justify-center w-10 h-10 bg-red-400 rounded-lg">
            <Icon className="lg:w-6 lg:h-6 w-4 h-4" />
          </div>
          <Text styleTo="h3" tag="h3" className="xl:text-left">
            {name}
          </Text>
        </div>
        <div>
          <Text className="text-left">{description}</Text>
        </div>
        <div>
          <ul className="space-y-1">
            {features.map((feature, i) => (
              <li key={`feature-${i}`}>
                <BulletPoint>{feature}</BulletPoint>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="xl:w-2/3 companionCarousel">
        <Carousel
          showThumbs={false}
          autoPlay
          swipeable
          infiniteLoop
          showStatus={false}
          showArrows={false}
          renderIndicator={(clickHandler, isSelected, i) => (
            <li key={`feature-${i}`}>
              <button
                className={`focus:outline-none transform hover:-translate-y-1 focus:-translate-y-1 hover:text-red-400 focus:text-red-400 w-10 h-1 transition duration-200 ease-out block rounded-full ${
                  isSelected ? "bg-white" : " bg-gray-700"
                }`}
                onClick={clickHandler}
              >
                &nbsp;
              </button>
            </li>
          )}
        >
          {images.map(({ node }, i) => (
            <div className="rounded-xl xl:h-96 xl:px-4 xl:py-8 p-6 bg-red-500">
              <Image
                fluid={node.childImageSharp.fluid}
                alt={`companion-${i}`}
                key={`${name}-${i}`}
                draggable={false}
                className="rounded-xl w-full h-full"
                imgStyle={{ objectFit: "contain" }}
              ></Image>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Companion
