import React, { useState, useEffect, useRef } from "react"

// Component imports
import Text from "./Text"

// Static assets
import { ReactComponent as Minus } from "../images/icons/minus-solid.svg"
import { ReactComponent as Plus } from "../images/icons/plus-solid.svg"

interface Props {
  icon: React.ReactNode
  question: string
  answer: string
}

const FAQ = ({ icon, question, answer }: Props) => {
  // State
  const [expand, setExpand] = useState(false)

  // Refs
  const faqContainer = useRef(null)
  const faqAnswer = useRef(null)

  // Helpers
  useEffect(() => {
    if (expand) {
      faqContainer.current.style.height = `${faqAnswer.current.offsetHeight}px`
    } else {
      faqContainer.current.style.height = "0px"
    }
  })

  const Icon = icon

  return (
    <div>
      {/* Question */}

      <button
        onClick={() => setExpand(!expand)}
        className="relative z-10 flex items-center justify-center w-full p-3 space-x-2 transition duration-300 ease-out transform bg-red-400 rounded-lg focus:outline-none hover:-translate-y-1 focus:-translate-y-1 hover:shadow-lg focus:shadow-lg"
      >
        <div className="flex items-center justify-center w-full">
          <Icon className="w-6 h-6 mr-2" />
          <Text>{question}</Text>
        </div>
        <div>
          {expand ? (
            <Minus className="w-4 h-4" />
          ) : (
            <Plus className="w-4 h-4" />
          )}
        </div>
      </button>

      {/* Answer */}

      <div
        ref={faqContainer}
        className="overflow-hidden transition-all duration-300 ease-out transform -translate-y-6 bg-gray-900 rounded-lg"
        style={{ height: 0 }}
      >
        <div className="p-6 pt-12" ref={faqAnswer}>
          <Text>{answer}</Text>
        </div>
      </div>
    </div>
  )
}

export default FAQ
