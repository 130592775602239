import React from "react"

interface Props {
  gradient: Array<string>
  children: string
  icon: React.ReactNode
  url: string
}

const SocialMedia = ({ gradient, children, icon, url }: Props) => {
  const Icon = icon

  return (
    <div className="relative group">
      <a
        className="relative z-10 flex items-center justify-center p-6 transition duration-300 ease-out bg-gray-900 rounded-lg"
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        <Icon className="w-8 h-8 mr-4 text-red-500" />
        {children}
      </a>
      <div
        style={{
          background: `linear-gradient(to right, ${gradient[0]} 0%, ${gradient[1]} 50%, ${gradient[2]} 100%)`,
        }}
        className="absolute inset-0 transition duration-300 ease-out delay-75 transform bg-red-500 rounded-lg opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-focus:translate-x-1 group-hover:translate-x-1 group-focus:translate-y-1 group-hover:translate-y-1"
      >
        &nbsp;
      </div>
    </div>
  )
}

export default SocialMedia
