import React, { useState, useEffect } from "react"

// Component imports
import Text from "./Text"

// Static assets
import {
  FaCheck as Check,
  FaPlus as Plus,
  FaChevronRight as Right,
  FaChevronLeft as Left,
} from "react-icons/fa"

interface Props {
  href: string
  name: string
  price: number
  features: string[]
  Icon: any
  addition?: string
}

const PriceTable = ({ href, name, price, features, Icon, addition }: Props) => {
  // State
  const [page, setPage] = useState(1)
  const [pageUpperBound, setPageUpperBound] = useState(0)

  // Helpers
  useEffect(() => {
    setPageUpperBound(Math.ceil(features.length / 5) * 1)
  }, [pageUpperBound, features.length])

  useEffect(() => {
    if (addition) {
      features.unshift(addition)
    }
  }, [addition])

  const handleIncrement = () => {
    if (page < pageUpperBound) {
      setPage(page + 1)
    }
  }

  const handleDecrement = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  // Components
  const Feature = ({
    children,
    usePlus,
  }: {
    children: string
    usePlus?: boolean
  }) => (
    <li className="flex items-center space-x-4">
      <div className="w-3 h-3">
        {usePlus ? (
          <Plus className="w-3 h-3 text-green-500" />
        ) : (
          <Check className="w-3 h-3 text-green-500" />
        )}
      </div>
      <div>
        <Text className="lg:text-base text-sm font-light tracking-widest">
          {children}
        </Text>
      </div>
    </li>
  )

  return (
    <div className="lg:p-12 lg:space-y-8 rounded-xl flex flex-col w-full p-6 space-y-4 bg-gray-900">
      <div className="md:space-x-4 md:flex md:items-center md:space-y-0 lg:space-x-6 space-y-4">
        <div className="lg:w-12 lg:h-12 flex items-center justify-center w-10 h-10 bg-red-400 rounded-lg">
          <Icon className="lg:w-6 lg:h-6 w-4 h-4" />
        </div>
        <Text styleTo="h3" tag="h3" className="tracking-3px">
          {name}
        </Text>
      </div>
      <div className="md:space-y-0 md:flex md:items-center md:justify-between space-y-4">
        <div className="space-y-1">
          <Text styleTo="h4" tag="h3" className="tracking-3px">
            ${price}
          </Text>
          <Text className="text-sm text-gray-300">Billed Monthly</Text>
        </div>
        <div>
          <ul className=" flex justify-center space-x-3">
            <li>
              <button
                onClick={handleDecrement}
                className="focus:outline-none hover:bg-red-500 focus:bg-red-500 rounded-xl flex items-center justify-center w-8 h-8 transition-all duration-200 ease-in-out bg-red-400"
              >
                <Left className="w-4 h-4" />
              </button>
            </li>
            <li>
              <button
                onClick={handleIncrement}
                className="focus:outline-none hover:bg-red-500 focus:bg-red-500 rounded-xl flex items-center justify-center w-8 h-8 transition-all duration-200 ease-in-out bg-red-400"
              >
                <Right className="w-4 h-4" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-1 space-y-4">
        <div className="space-y-3">
          <ul className="lg:w-10/12 lg:mx-auto flex flex-col justify-center space-y-1">
            {features.slice(page * 5 - 5, page * 5).map((feature, i) => (
              <Feature
                key={`feature-${i}`}
                usePlus={
                  typeof addition !== "undefined" &&
                  addition?.length !== 0 &&
                  i === 0 &&
                  page === 1
                }
              >
                {feature}
              </Feature>
            ))}
          </ul>
        </div>
      </div>
      <div className="text-center">
        <Text className="text-xs text-gray-300">
          Page {page} of {pageUpperBound}
        </Text>
      </div>
      <div className="md:flex md:justify-center">
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="focus:shadow-lg hover:shadow-lg focus:outline-none hover:-translate-y-1 focus:-translate-y-1 block w-10/12 p-3 mx-auto text-center transition duration-300 ease-out transform bg-red-400 rounded-lg"
        >
          Checkout
        </a>
      </div>
    </div>
  )
}

export default PriceTable
