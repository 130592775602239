import React, { useRef } from "react"

import * as Yup from "yup"

// Component imports
import { Formik, Form, Field, ErrorMessage } from "formik"
import Text from "./Text"

const ContactForm = () => {
  // Refs
  const form = useRef(null)

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .max(30, "Name must be less than 30 characters.")
          .required("Your full name is required."),
        email: Yup.string()
          .email("Please enter a valid email address.")
          .required("Please enter a valid email address."),
        message: Yup.string()
          .max(200, "Message must be 200 characters or less.")
          .required("Please enter a message."),
      })}
      onSubmit={({ setSubmitting }: any) => {
        form.current.submit()
        setSubmitting(false)
      }}
    >
      <Form
        className="lg:gap-4 md:w-10/12 md:mx-auto lg:w-full grid gap-8"
        ref={form}
        method="POST"
        action="https://formspree.io/mnqgradp"
      >
        <div>
          <label htmlFor="email" className="block mb-2">
            <Text className="text-lg tracking-widest">
              So, what's your name?
            </Text>
          </label>
          <Field
            type="text"
            name="name"
            placeholder="Name..."
            id="name"
            className="focus:outline-none focus:shadow-lg block w-full p-4 text-white placeholder-gray-500 transition duration-300 ease-out bg-gray-900 rounded-lg"
          />
          <div className="mt-2 text-sm text-red-400">
            <ErrorMessage name="name" />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block mb-2">
            <Text className="text-lg tracking-widest">
              How about your e-mail?
            </Text>
          </label>
          <Field
            type="text"
            name="email"
            placeholder="Email..."
            id="email"
            className="focus:outline-none focus:shadow-lg block w-full p-4 text-white placeholder-gray-500 transition duration-300 ease-out bg-gray-900 rounded-lg"
          />
          <div className="mt-2 text-sm text-red-400">
            <ErrorMessage name="email" />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block mb-2">
            <Text className="text-lg tracking-widest">
              Nice! Now what's up?
            </Text>
          </label>
          <Field
            as="textarea"
            name="message"
            placeholder="Message..."
            id="message"
            className="focus:outline-none focus:shadow-lg block w-full h-40 p-4 text-white placeholder-gray-500 transition duration-300 ease-out bg-gray-900 rounded-lg resize-none"
          />
          <div className="mt-2 text-sm text-red-400">
            <ErrorMessage name="message" />
          </div>
        </div>
        <div className="lg:justify-start flex justify-center">
          <button
            type="submit"
            className="lg:w-auto hover:shadow-lg focus:shadow-lg tracking-3px focus:outline-none hover:-translate-y-1 focus:-translate-y-1 w-full px-8 py-4 text-lg transition duration-300 ease-out transform bg-gray-900 rounded-lg"
          >
            Send
          </button>
        </div>
      </Form>
    </Formik>
  )
}

export default ContactForm
