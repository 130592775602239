import React from "react"

// Component imports
import Text from "../components/Text"

interface Props {
  review: string
  avatarURL: string
  discordTag: string
}

const Review = ({ review, avatarURL, discordTag }: Props) => {
  return (
    <div className="relative p-6 py-16 mt-10 text-left bg-gray-900 rounded-lg lg:mt-16">
      <Text tag="h3" styleTo="h5" className="mb-6">
        {discordTag}
      </Text>
      <Text>{review}</Text>

      {/* Avatar */}
      <div className="absolute inset-x-0 top-0 flex justify-center">
        <img
          src={avatarURL}
          alt={discordTag}
          className="object-cover w-16 h-16 transform -translate-y-8 rounded-full lg:h-24 lg:-translate-y-12"
        />
      </div>
    </div>
  )
}

export default Review
