import React from "react"

interface Props {
  icon: React.ReactNode
  children: string
  url: string
}

const Download = ({ icon, children, url }: Props) => {
  const Icon = icon

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center w-10 h-10 transition duration-300 ease-out transform bg-red-400 rounded-full focus:shadow-lg hover:shadow-lg sm:w-12 sm:h-12 focus:outline-none hover:-translate-y-1 focus:-translate-y-1"
    >
      <Icon className="w-6 h-6" />
      <span className="sr-only">{children}</span>
    </a>
  )
}

export default Download
