import React from "react"

import { graphql, useStaticQuery } from "gatsby"

// Component imports
import { Carousel } from "react-responsive-carousel"
import Companion from "./Companion"

// Static assets
import { ReactComponent as Chevron } from "../images/icons/chevron.svg"
import { ReactComponent as Autofill } from "../images/icons/Autofill.svg"
import { ReactComponent as CompanionIcon } from "../images/icons/Companion.svg"
import { ReactComponent as InventoryTracking } from "../images/icons/inventory-tracker.svg"
import { ReactComponent as Mobile } from "../images/icons/Mobile.svg"
import { ReactComponent as NotificationHub } from "../images/icons/notification-hub.svg"
import { ReactComponent as Scripts } from "../images/icons/Scripts.svg"
import { ReactComponent as WebTwitter } from "../images/icons/web-twitter.svg"

// Styles
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Companions = () => {
  // Queries
  const data = useStaticQuery(graphql`
    {
      autofill: allFile(
        filter: { relativeDirectory: { eq: "kraken-autofill" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      companions: allFile(filter: { relativeDirectory: { eq: "companions" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      krakenMobile: allFile(
        filter: { relativeDirectory: { eq: "kraken-mobile" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      krakenScripts: allFile(
        filter: { relativeDirectory: { eq: "kraken-scripts" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      inventoryTracker: allFile(
        filter: { relativeDirectory: { eq: "inventory-tracker" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      webTwitter: allFile(
        filter: { relativeDirectory: { eq: "web-twitter" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      notificationHub: allFile(
        filter: { relativeDirectory: { eq: "notification-hub" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="companionsMainCarousel overflow-hidden">
      <Carousel
        className="xl:px-20 xl:pb-10 pb-16"
        showThumbs={false}
        showStatus={false}
        renderIndicator={(clickHandler, isSelected, i) => (
          <li key={`feature-${i}`}>
            <button
              className={`focus:outline-none  hover:text-red-400 focus:text-red-400 w-2 h-2 transition duration-200 ease-out rounded-full ${
                isSelected ? "bg-red-400" : " bg-gray-700"
              }`}
              onClick={clickHandler}
            >
              &nbsp;
            </button>
          </li>
        )}
        renderArrowNext={(clickHandler, hasNext) => (
          <div className="xl:items-center absolute inset-y-0 right-0 flex items-end">
            <button
              onClick={clickHandler}
              className="focus:outline-none hover:scale-105 focus:scale-105 xl:translate-x-18 xl:translate-y-0 z-20 transition duration-200 ease-out transform translate-y-10"
            >
              <Chevron className="w-10 h-10" />
            </button>
          </div>
        )}
        renderArrowPrev={(clickHandler, hasPrev) => (
          <div className="xl:items-center absolute inset-y-0 left-0 flex items-end">
            <button
              onClick={clickHandler}
              className="focus:outline-none hover:scale-105 focus:scale-105 xl:translate-y-0 xl:-translate-x-18 z-20 transition duration-200 ease-out transform translate-y-10"
            >
              <Chevron className="w-10 h-10 transform rotate-180" />
            </button>
          </div>
        )}
        infiniteLoop
      >
        <Companion
          Icon={Autofill}
          name="Kraken Autofill"
          description="A stellar custom-developed chrome extension complementary to your subscription aimed to automate your browser experience."
          features={[
            "Autofill and Autocheckout",
            "Auto Captcha Checker",
            "Shopify Step Bypass",
            "Typing Simulation",
            "Clean Interface",
          ]}
          images={data.autofill.edges}
        />
        <Companion
          Icon={CompanionIcon}
          name="Kraken Companion"
          description="Our dynamic AIO toolbox contains dozens of modules for all types of members, equipped with the latest and greatest features ensures your success!"
          features={[
            "Splash Browser",
            "OneClick Trainer",
            "Proxy Generator",
            "Link Opener",
            "Twitter Giveaway Enterer",
          ]}
          images={data.companions.edges}
        />
        <Companion
          Icon={Mobile}
          name="Kraken Mobile"
          description="Our all-in-one mobile application provides our members with a convenient way of managing their reselling portfolio from the palm of their hands."
          features={[
            "Inventory Tracker",
            "Release Information",
            "Raffle monitor",
            "Customizable Notifications",
            "Browser with Autofill",
          ]}
          images={data.krakenMobile.edges}
        />
        <Companion
          Icon={Scripts}
          name="Kraken Scripts"
          description=" Kraken Scripts is a Discord automation software used to secure Discord-based drops at speeds faster than imaginable."
          features={[
            "Auto Link opener (<30ms)",
            "Auto Nitro Claimer",
            "Auto Server Joiner",
            "Multiple Claiming Tokens",
            "Multiple Monitoring Tokens",
          ]}
          images={data.krakenScripts.edges}
        />
        <Companion
          Icon={InventoryTracking}
          name="Inventory Tracker"
          description=" The inventory tracker allows you to track all of your expenses and get detailed reports on your reselling portfolio from A to Z."
          features={[
            "Track Inventory Value",
            "Track Unrealized Profits",
            "StockX Integration",
            "Bot Tracking",
            "Detailed Item Reports",
          ]}
          images={data.inventoryTracker.edges}
        />
        <Companion
          Icon={WebTwitter}
          name="Web Twitter Monitor"
          description="The fastest non-discord-based twitter monitor, bypassing Discord's API delay with delivery straight to your browser."
          features={[
            "Auto Link Opener",
            "Automatic Invite Claimer",
            "Discord API Delay Bypass",
            "Sound Alerts",
            "24/7 Monitoring",
          ]}
          images={data.webTwitter.edges}
        />
        <Companion
          Icon={NotificationHub}
          name="Notification Hub"
          description=" Our online notification hub ensures that you receive alerts and notifications the moment they are sent out in our discord."
          features={[
            "Desktop Notifications",
            "Up to 5 pings per column",
            "Sound Alerts",
            "Customizable Interface",
            "Neat Columns",
          ]}
          images={data.notificationHub.edges}
        />
      </Carousel>
    </div>
  )
}

export default Companions
