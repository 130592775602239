import React from "react"

// Component imports
import AnimatedNumber from "animated-number-react"
import { InView } from "react-intersection-observer"

interface Props {
  preDecoration?: string
  sufDecoration?: string
  val: number
  description: string
}

const Statistic = ({
  preDecoration,
  sufDecoration,
  description,
  val,
}: Props) => {
  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <div
          className="flex flex-col items-center justify-center h-full p-6 text-center bg-red-400 rounded-lg"
          ref={ref}
        >
          <h3 className="font-bebasNeue tracking-3px text-4xl">
            {preDecoration && <span>{preDecoration}</span>}
            {inView && (
              <AnimatedNumber
                value={val}
                formatValue={(value) => value.toFixed(0)}
              />
            )}
            {sufDecoration && <span>{sufDecoration}</span>}
          </h3>
          <p className="tracking-3px text-lg uppercase">{description}</p>
        </div>
      )}
    </InView>
  )
}

export default Statistic
