import React, { useState, useEffect, useRef } from "react"

import { graphql, useStaticQuery } from "gatsby"

import Parallax from "parallax-js"
import jump from "jump.js"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Text from "../components/Text"
import Image from "gatsby-image"
import FAQ from "../components/FAQ"
import Feature from "../components/Feature"
import Heading from "../components/Heading"
import Statistic from "../components/Statistic"
import Download from "../components/Download"
import SocialMedia from "../components/SocialMedia"
import ContactForm from "../components/ContactForm"
import { Fade } from "react-awesome-reveal"
import { Carousel } from "react-responsive-carousel"
import { TwitterTimelineEmbed } from "react-twitter-embed"
import Slider from "react-slick"
import Companions from "../components/Companions"
import Div100Vh from "react-div-100vh"

// Static assets

import { ReactComponent as World } from "../images/icons/globe-americas-solid.svg"

import { ReactComponent as Twitter } from "../images/icons/twitter-brands.svg"
import { ReactComponent as Question } from "../images/icons/question-circle-solid.svg"
import { ReactComponent as Apple } from "../images/icons/apple-brands.svg"
import { ReactComponent as Windows } from "../images/icons/windows-brands.svg"
import { ReactComponent as Android } from "../images/icons/android-brands.svg"
import { ReactComponent as iOS } from "../images/icons/app-store-ios-brands.svg"
import { ReactComponent as Robot } from "../images/icons/robot-solid.svg"
import { ReactComponent as Dollar } from "../images/icons/dollar-sign-solid.svg"
import { ReactComponent as Instagram } from "../images/icons/instagram-brands.svg"
import { ReactComponent as QuestionCircle } from "../images/icons/question-circle-regular.svg"

import { ReactComponent as Certificate } from "../images/icons/certificate-solid.svg"

import { ReactComponent as Kraken } from "../images/kraken.svg"
import { ReactComponent as Flares } from "../images/flares.svg"
import { ReactComponent as CircleGroup } from "../images/circleGroup.svg"
import { ReactComponent as Cross } from "../images/cross.svg"
import { ReactComponent as PaperPlane } from "../images/icons/free.svg"
import { ReactComponent as Coins } from "../images/icons/member.svg"
import { ReactComponent as Stack } from "../images/icons/premium.svg"

import { ReactComponent as UisBell } from "../images/icons/UisBell.svg"
import { ReactComponent as UisBellSlash } from "../images/icons/UisBellSlash.svg"
import { ReactComponent as UisChartLine } from "../images/icons/UisChartLine.svg"
import { ReactComponent as UisClipboardNotes } from "../images/icons/UisClipboardNotes.svg"
import { ReactComponent as UisCommentAltExclamation } from "../images/icons/UisCommentAltExclamation.svg"
import { ReactComponent as UisCommentInfoAlt } from "../images/icons/UisCommentInfoAlt.svg"
import { ReactComponent as UisComparison } from "../images/icons/UisComparison.svg"
import { ReactComponent as UisCreditCard } from "../images/icons/UisCreditCard.svg"
import { ReactComponent as UisExternalLinkAlt } from "../images/icons/UisExternalLinkAlt.svg"
import { ReactComponent as UisEye } from "../images/icons/UisEye.svg"
import { ReactComponent as UisFolderInfo } from "../images/icons/UisFolderInfo.svg"
import { ReactComponent as UisGlobe } from "../images/icons/UisGlobe.svg"
import { ReactComponent as UisLinkAdd } from "../images/icons/UisLinkAdd.svg"
import { ReactComponent as UisMegaPhone } from "../images/icons/UisMegaPhone.svg"
import { ReactComponent as UisMonitor } from "../images/icons/UisMonitor.svg"
import { ReactComponent as UisNewspaper } from "../images/icons/UisNewspaper.svg"
import { ReactComponent as UisParcel } from "../images/icons/UisParcel.svg"
import { ReactComponent as UisQrcodeScan } from "../images/icons/UisQrcodeScan.svg"
import { ReactComponent as UisRobot } from "../images/icons/UisRobot.svg"
import { ReactComponent as UisServer } from "../images/icons/UisServer.svg"
import { ReactComponent as UisServers } from "../images/icons/UisServers.svg"
import { ReactComponent as UisStepBackwardAlt } from "../images/icons/UisStepBackwardAlt.svg"
import { ReactComponent as UisTrophy } from "../images/icons/UisTrophy.svg"
import { ReactComponent as UisUsersAlt } from "../images/icons/UisUsersAlt.svg"
import { ReactComponent as UisWall } from "../images/icons/UisWall.svg"
import { ReactComponent as UisWrench } from "../images/icons/UisWrench.svg"
import { ReactComponent as UisAnalysis } from "../images/icons/UisAnalysis.svg"
import { ReactComponent as UisRedo } from "../images/icons/UisRedo.svg"
import { ReactComponent as UisWrapText } from "../images/icons/UisWrapText.svg"

// CSS
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import indexStyles from "../css/index.module.css"

import Review from "../components/Review"
import PriceTable from "../components/PriceTable"
import Preloader from "../components/Preloader"

const Index = () => {
  // Queries
  const data = useStaticQuery(graphql`
    {
      phone: file(relativePath: { eq: "phone.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      humanitarianHelp: file(relativePath: { eq: "humanitarian-help.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      youngAndHappy: file(relativePath: { eq: "young-and-happy.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      digitalNomad: file(relativePath: { eq: "digital-nomad.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      questionsBro: file(relativePath: { eq: "questions-bro.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      socialMedia: file(relativePath: { eq: "social-media.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contact: file(relativePath: { eq: "contact.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      reviews: file(relativePath: { eq: "reviews.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      companions: allFile(
        filter: { relativeDirectory: { eq: "companions" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      scripts: allFile(
        filter: { relativeDirectory: { eq: "scripts" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      twitter: allFile(
        filter: { relativeDirectory: { eq: "twitter" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      freeTier: file(relativePath: { eq: "free-tier.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      memberTier: file(relativePath: { eq: "member-tier.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      premiumTier: file(relativePath: { eq: "premium-tier.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // State
  const [showAllFeatures, setShowAllFeatures] = useState(false)

  // Refs
  const parallaxScene = useRef(null)

  // Helpers
  useEffect(() => {
    new Parallax(parallaxScene.current, {
      scalarX: 2,
      scalarY: 2,
    })
  }, [parallaxScene])

  return (
    <Layout>
      <SEO title="Home" />

      {/* Preloader */}
      <Preloader />

      {/* Hero */}
      <Div100Vh>
        <Section className="mt-14 h-full">
          <div className="relative flex flex-col items-center justify-center w-full h-full">
            {/* Alert */}
            <div className="md:block md:transform md:-translate-y-32 hidden">
              <div className="rounded-xl flex items-center p-4 space-x-4 bg-gray-900">
                <div className="px-3 bg-red-400 rounded-lg">
                  <Text className="text-sm">New!</Text>
                </div>
                <Text className="text-sm tracking-widest">
                  Interested in becoming a member? Join our{" "}
                  <a
                    className="focus:outline-none hover:text-white focus:text-white text-blue-500 underline duration-200 ease-out"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc2VuEf1MPvoOK09BtAxLfG6VRVu6XKwFBA08JJvtmHg9uoJg/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    waitlist
                  </a>
                  .
                </Text>
              </div>
            </div>
            <div className="lg:space-y-10 relative z-20 space-y-5">
              <div className="md:grid md:grid-cols-3 md:gap-8 md:justify-items-center md:items-center md:space-y-0 space-y-3">
                <Image
                  fluid={data.logo.childImageSharp.fluid}
                  alt="Kraken Notify"
                  className="md:order-2 md:w-full md:h-48 xl:h-56 w-28 h-28"
                  imgStyle={{ objectFit: "contain" }}
                  draggable={false}
                />
                <Text styleTo="h1" tag="h1" className="md:order-1 lg:text-7xl">
                  Kraken
                </Text>
                <Text styleTo="h1" tag="h1" className="md:order-3 lg:text-7xl">
                  Notify
                </Text>
              </div>
              <div className="space-y-6">
                <Text className="md:text-center">
                  Welcome to the most advanced reselling network
                </Text>
                <div className=" md:mx-auto">
                  <button
                    onClick={() => jump("#features", { offset: -150 })}
                    className="focus:shadow-lg md:w-1/2 xl:w-1/4 hover:shadow-lg focus:outline-none hover:-translate-y-1 focus:-translate-y-1 block w-10/12 p-3 mx-auto text-center transition duration-300 ease-out transform bg-red-400 rounded-lg"
                  >
                    Explore Kraken
                  </button>
                </div>
              </div>
            </div>
            <div
              className="absolute inset-0"
              ref={parallaxScene}
              style={{ position: "absolute" }}
            >
              <div className="select-none">&nbsp;</div>
              <div className="z-10 w-full h-full" data-depth="0.2">
                <div className="flex flex-col items-center justify-center h-full text-red-500">
                  <div className="md:grid-cols-3 md:h-48 xl:h-56 md:gap-8 md:items-center md:justify-items-center md:grid md:space-y-0 md:-translate-y-8 space-y-3 transform -translate-y-2">
                    <Text styleTo="h1" tag="h1" className="lg:text-7xl">
                      Kraken
                    </Text>
                    <Text
                      styleTo="h1"
                      tag="h1"
                      className="md:col-start-3 lg:text-7xl lg:translate-x-1"
                    >
                      Notify
                    </Text>
                  </div>
                </div>
              </div>
              <div className="w-full h-full">
                <div className="md:absolute md:inset-0 md:flex md:flex-col md:items-center md:justify-center hidden">
                  <Kraken className="md:transform md:-translate-y-14 w-full" />
                </div>
              </div>
              <div className="w-full h-full">
                <div className="md:flex md:items-center md:absolute md:inset-0 md:justify-between hidden">
                  <div>
                    <Cross className="transform -translate-y-16" />

                    <CircleGroup className="lg:translate-y-32 transform translate-y-16" />
                  </div>
                  <div>
                    <Flares className="lg:-translate-y-60 transform -translate-y-16" />
                    <Cross className="transform translate-y-16" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Div100Vh>

      {/* Features */}
      <Section id="features" className="relative z-20">
        <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
          <div className="mb-3">
            <Heading>Features</Heading>
          </div>
          <Text tag="p" styleTo="h4" className="tracking-3px text-center">
            some features that make us{" "}
            <span className="text-red-500">great</span>
          </Text>
        </div>
        {!showAllFeatures && (
          <Slider
            slidesToShow={4}
            pauseOnHover
            speed={250}
            autoplay
            infinite
            autoplaySpeed={2500}
            className="lg:-mx-8 mb-12"
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            <Feature
              title="Exclusive Backdoors"
              Icon={UisStepBackwardAlt}
              description="Obtain a fully confirmed order for a limited item before the item even releases!"
            />
            <Feature
              title="Free Proxies"
              Icon={UisServers}
              description="Freshly generated DC proxies available to each Premium member on-demand, every single day."
            />
            <Feature
              title="Free Servers"
              Icon={UisServer}
              description="A blazing fast server generated for each Premium member on-demand, every single day."
            />
            <Feature
              title="Free Slots"
              Icon={UisCreditCard}
              description="Free slots ran on the best bots along with the best proxies - FREE of charge."
            />
            <Feature
              title="Speedy Monitors"
              Icon={UisMonitor}
              description="THE FASTEST sneaker + retail monitors available, picking up EVERY drop and restock the millisecond they happen."
            />
            <Feature
              title="Brick Buyback"
              Icon={UisWall}
              description="Purchase a brick? Sell to StockX and we will pay for your loss of profit as a premium member (to retail)!"
            />
            <Feature
              title="Free Rentals"
              Icon={UisRobot}
              description="Free TOP tier bots given our to our members for select releases on a raffle or FCFS basis."
            />
            <Feature
              title="Exclusive Groupbuys"
              Icon={UisUsersAlt}
              description="Have access to exclusive group buys with the best bots, proxies, and tools on the market."
            />
            <Feature
              title="Video and Written Guides"
              Icon={UisNewspaper}
              description="Have access to numerous written guides along with video guides helping you every step of the way."
            />
            <Feature
              title="Release Guides"
              Icon={UisClipboardNotes}
              description="Have access to extremely accurate release guides to know exactly what is dropping, when and where."
            />
            <Feature
              title="Early Links"
              Icon={UisLinkAdd}
              description="Have access to accurate early release links for drops such as Kith, Undefeated, Xhibition and more."
            />
            <Feature
              title="Drop Updates"
              Icon={UisCommentAltExclamation}
              description="Live drop updates throughout every hyped release helping you secure as many pairs as possible."
            />
            <Feature
              title="Voice Chat Setups + Drops"
              Icon={UisMegaPhone}
              description="Need help prior or during a drop? Join voice chat and we’ll help you live with whatever you need."
            />
            <Feature
              title="Bot Flipping"
              Icon={UisAnalysis}
              description="Extremely advanced bot flipping advice and information to allow for massive profit when bot flipping."
            />
            <Feature
              title="Web Twitter Monitor"
              Icon={UisQrcodeScan}
              description="The FASTEST online Twitter monitor equipped with keywords, link opener, and so much more."
            />
            <Feature
              title="Lowkey Flips"
              Icon={UisBellSlash}
              description="An abundance of lowkey flips posted every single day for each region. Funko, toys, cards, & more."
            />
            <Feature
              title="Brick Flips"
              Icon={UisRedo}
              description="Get access to an absurd amount of brick flips to help keep you gaining profit, even on a slow month."
            />
            <Feature
              title="Sneaker Investments"
              Icon={UisChartLine}
              description="Detailed calls for various sneakers that are predicted to increase in value."
            />
            <Feature
              title="Stock Numbers"
              Icon={UisParcel}
              description="Stock Numbers on various sites for US + EU aquired from our Reverse Engineer Developers."
            />
            <Feature
              title="Drop Recaps"
              Icon={UisWrapText}
              description="An extensive analysis of various drops to help you directly improve on the next (Proxies, Bots, etc)."
            />
            <Feature
              title="Bot Checkout Monitors"
              Icon={UisMonitor}
              description="Instantly know what and when 20+ different bots are checking out in the moment."
            />
            <Feature
              title="Market Predictions"
              Icon={UisComparison}
              description="Accurate market predictions to be one big leap ahead of the game."
            />
            <Feature
              title="Early SNKRS Info"
              Icon={UisCommentInfoAlt}
              description="Early SNKRS info for events such as shock drops, exclusive access, SNKRS passes."
            />
            <Feature
              title="Early SNKRS ATC links"
              Icon={UisExternalLinkAlt}
              description="Early SNKRS add-to-cart links for all specific region's hyped releases ."
            />
            <Feature
              title="Notification System"
              Icon={UisBell}
              description="Be the first to know about shock drops via our premium SMS notification, as soon as they happen."
            />
            <Feature
              title="Bots & Tools"
              Icon={UisWrench}
              description="Get excellent support from our numerous dedicated staff members across the world."
            />
            <Feature
              title="Worldwide Support"
              Icon={UisGlobe}
              description="Get excellent support from our numerous dedicated staff members across the world."
            />
            <Feature
              title="Reward System"
              Icon={UisTrophy}
              description="Our rewards system allows you to collect tokens, redeem prizes, and open crates."
            />
            <Feature
              title="Private Tutorials"
              Icon={UisFolderInfo}
              description="Schedule an appointment with one of our expert resellers to gain guidance on any of your reselling requests."
            />
            <Feature
              title="Licks and Jigs"
              Icon={UisEye}
              description="Get access to multiple exclusive jigs and licks dedicated to loops, free food, products, games, and more."
            />
          </Slider>
        )}

        {showAllFeatures && (
          <>
            <div className="xl:mb-24 md:grid-cols-2 md:w-10/12 md:mx-auto lg:grid-cols-3 xl:grid-cols-4 lg:w-full xl:gap-4 relative grid gap-8 mb-12">
              <Fade
                cascade
                direction="up"
                damping={0.1}
                triggerOnce
                duration={500}
                className={indexStyles.fullWidthChildren}
              >
                <Feature
                  title="Exclusive Backdoors"
                  Icon={UisStepBackwardAlt}
                  description="Obtain a fully confirmed order for a limited item before the item even releases!"
                />
                <Feature
                  title="Free Proxies"
                  Icon={UisServers}
                  description="Freshly generated DC proxies available to each Premium member on-demand, every single day."
                />
                <Feature
                  title="Free Servers"
                  Icon={UisServer}
                  description="A blazing fast server generated for each Premium member on-demand, every single day."
                />
                <Feature
                  title="Free Slots"
                  Icon={UisCreditCard}
                  description="Free slots ran on the best bots along with the best proxies - FREE of charge."
                />
                <Feature
                  title="Speedy Monitors"
                  Icon={UisMonitor}
                  description="THE FASTEST sneaker + retail monitors available, picking up EVERY drop and restock the millisecond they happen."
                />
                <Feature
                  title="Brick Buyback"
                  Icon={UisWall}
                  description="Purchase a brick? Sell to StockX and we will pay for your loss of profit as a premium member (to retail)!"
                />
                <Feature
                  title="Free Rentals"
                  Icon={UisRobot}
                  description="Free TOP tier bots given our to our members for select releases on a raffle or FCFS basis."
                />
                <Feature
                  title="Exclusive Groupbuys"
                  Icon={UisUsersAlt}
                  description="Have access to exclusive group buys with the best bots, proxies, and tools on the market."
                />
                <Feature
                  title="Video and Written Guides"
                  Icon={UisNewspaper}
                  description="Have access to numerous written guides along with video guides helping you every step of the way."
                />
                <Feature
                  title="Release Guides"
                  Icon={UisClipboardNotes}
                  description="Have access to extremely accurate release guides to know exactly what is dropping, when and where."
                />
                <Feature
                  title="Early Links"
                  Icon={UisLinkAdd}
                  description="Have access to accurate early release links for drops such as Kith, Undefeated, Xhibition and more."
                />
                <Feature
                  title="Drop Updates"
                  Icon={UisCommentAltExclamation}
                  description="Live drop updates throughout every hyped release helping you secure as many pairs as possible."
                />
                <Feature
                  title="Voice Chat Setups + Drops"
                  Icon={UisMegaPhone}
                  description="Need help prior or during a drop? Join voice chat and we’ll help you live with whatever you need."
                />
                <Feature
                  title="Bot Flipping"
                  Icon={UisAnalysis}
                  description="Extremely advanced bot flipping advice and information to allow for massive profit when bot flipping."
                />
                <Feature
                  title="Web Twitter Monitor"
                  Icon={UisQrcodeScan}
                  description="The FASTEST online Twitter monitor equipped with keywords, link opener, and so much more."
                />
                <Feature
                  title="Lowkey Flips"
                  Icon={UisBellSlash}
                  description="An abundance of lowkey flips posted every single day for each region. Funko, toys, cards, & more."
                />
                <Feature
                  title="Brick Flips"
                  Icon={UisRedo}
                  description="Get access to an absurd amount of brick flips to help keep you gaining profit, even on a slow month."
                />
                <Feature
                  title="Sneaker Investments"
                  Icon={UisChartLine}
                  description="Detailed calls for various sneakers that are predicted to increase in value."
                />
                <Feature
                  title="Stock Numbers"
                  Icon={UisParcel}
                  description="Stock Numbers on various sites for US + EU aquired from our Reverse Engineer Developers."
                />
                <Feature
                  title="Drop Recaps"
                  Icon={UisWrapText}
                  description="An extensive analysis of various drops to help you directly improve on the next (Proxies, Bots, etc)."
                />
                <Feature
                  title="Bot Checkout Monitors"
                  Icon={UisMonitor}
                  description="Instantly know what and when 20+ different bots are checking out in the moment."
                />
                <Feature
                  title="Market Predictions"
                  Icon={UisComparison}
                  description="Accurate market predictions to be one big leap ahead of the game."
                />
                <Feature
                  title="Early SNKRS Info"
                  Icon={UisCommentInfoAlt}
                  description="Early SNKRS info for events such as shock drops, exclusive access, SNKRS passes."
                />
                <Feature
                  title="Early SNKRS ATC links"
                  Icon={UisExternalLinkAlt}
                  description="Early SNKRS add-to-cart links for all specific region's hyped releases ."
                />
                <Feature
                  title="Notification System"
                  Icon={UisBell}
                  description="Be the first to know about shock drops via our premium SMS notification, as soon as they happen."
                />
                <Feature
                  title="Bots & Tools"
                  Icon={UisWrench}
                  description="Get excellent support from our numerous dedicated staff members across the world."
                />
                <Feature
                  title="Worldwide Support"
                  Icon={UisGlobe}
                  description="Get excellent support from our numerous dedicated staff members across the world."
                />
                <Feature
                  title="Reward System"
                  Icon={UisTrophy}
                  description="Our rewards system allows you to collect tokens, redeem prizes, and open crates."
                />
                <Feature
                  title="Private Tutorials"
                  Icon={UisFolderInfo}
                  description="Schedule an appointment with one of our expert resellers to gain guidance on any of your reselling requests."
                />
                <Feature
                  title="Licks and Jigs"
                  Icon={UisEye}
                  description="Get access to multiple exclusive jigs and licks dedicated to loops, free food, products, games, and more."
                />
              </Fade>
            </div>
            <Text
              tag="h3"
              styleTo="h4"
              className="tracking-3px mb-6 text-center"
            >
              and so <span className="text-red-500">much</span> more...
            </Text>
          </>
        )}

        <a
          href="#features"
          onClick={() => {
            setShowAllFeatures(!showAllFeatures)
          }}
          className="focus:shadow-lg hover:shadow-lg focus:outline-none hover:-translate-y-1 focus:-translate-y-1 block w-64 p-3 mx-auto text-center transition duration-300 ease-out transform bg-red-400 rounded-lg"
        >
          <Text>{showAllFeatures ? "View Less" : "View All"}</Text>
        </a>
      </Section>

      {/* Companions */}
      <Section id="companions">
        <div className="relative">
          <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
            <div className="mb-3">
              <Heading>Companions</Heading>
            </div>
            <Text tag="p" styleTo="h4" className="tracking-3px text-center">
              some of our tools, <span className="text-red-500">free</span> with
              your membership
            </Text>
          </div>
          <div className="md:w-10/12 md:gap-20 lg:gap-32 xl:gap-40 md:mx-auto xl:w-full relative z-10 grid gap-12">
            <Companions />
          </div>
        </div>
      </Section>

      {/* Success */}
      <div id="success" className={indexStyles.successBackground}>
        <Section>
          <div className="relative">
            <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
              <div className="mb-3">
                <Heading>Success</Heading>
              </div>
              <Text tag="p" styleTo="h4" className="tracking-3px text-center">
                still not convinced?
              </Text>
            </div>
            <div className="md:w-10/12 md:mx-auto lg:w-full lg:grid-cols-3 lg:gap-20 relative z-10 grid gap-8">
              <div className="xl:gap-10 grid h-full gap-8">
                <Fade cascade triggerOnce direction="left" duration={500}>
                  <Statistic
                    val={20}
                    sufDecoration="+"
                    description="Dedicated staff members"
                  />
                  <Statistic
                    val={2}
                    preDecoration="$"
                    sufDecoration="M"
                    description="In member profits"
                  />
                  <Statistic
                    val={100}
                    sufDecoration="%"
                    description="satisfaction
guaranteed"
                  />
                </Fade>
              </div>
              <Fade
                triggerOnce
                direction="right"
                className="lg:col-span-2"
                duration={500}
              >
                <div>
                  <div className="mb-6">
                    <TwitterTimelineEmbed
                      sourceType="profile"
                      screenName="NotifyKraken"
                      options={{ height: 624 }}
                      theme="dark"
                    />
                  </div>
                  <a
                    href="https://twitter.com/NotifyKraken"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="focus:outline-none focus:shadow-lg hover:shadow-lg focus:-translate-y-1 hover:-translate-y-1 flex items-center justify-center w-full py-4 text-lg tracking-widest text-center lowercase transition duration-300 ease-out transform bg-gray-900 rounded-lg shadow-md"
                  >
                    <Twitter className="w-6 h-6 mr-4" />
                    see more success
                  </a>
                </div>
              </Fade>
            </div>
            <Fade triggerOnce duration={500}>
              <div>
                <Image
                  fluid={data.youngAndHappy.childImageSharp.fluid}
                  className="lg:absolute lg:translate-x-4 lg:top-0 lg:left-0 lg:w-48 xl:w-64 lg:transform lg:translate-y-48 xl:translate-y-32"
                  style={{ position: "absolute" }}
                  alt="Man and woman jumpig in the air in joy."
                  draggable={false}
                />
              </div>
            </Fade>
          </div>
        </Section>
      </div>

      {/* Reviews */}
      <Section>
        <div className="relative">
          <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
            <div className="mb-3">
              <Heading>Reviews</Heading>
            </div>
            <Text tag="p" styleTo="h4" className="tracking-3px text-center">
              some honest words from our members
            </Text>
          </div>

          <Carousel
            className="relative z-10 rounded-full"
            showThumbs={false}
            showIndicators={true}
            showArrows={false}
            showStatus={false}
            autoPlay
            infiniteLoop
          >
            <Review
              key="review1"
              avatarURL="/images/testimonials/Hobbles.jpg"
              discordTag="Hobbles#9999"
              review="I recently got back into sneaker reselling. I am happy I found kraken notify when I did. I have poured tons of money into other cool groups. I don’t think they stand as good of kraken. You guys always have the links keys word everything days before the drop like I have never seen. I look in success and I see all the free *lots. People are cooking a lot even thought they don’t have a bot. People spends thousands on bots. With kraken and there monitors it is very possible that you can go manual and cop on the restock monitors. Kraken also has a ton of free proxies. It feels like every day I get a message about a new giveaway. The staff on kraken is top tier. They are always in VC and chat helping out people with any issue they might in counter while they try and cop. They always work with you so you can secure the item. With this new dashboard coming our way, This cook group will be top tier! I highly recommend this to any one, even if you don’t like hype stuff you can take advantage of there amazing deals that they post everyday on the monitor. I have copped a ton of things for personal that I wanted at a insane deal! let’s keep this community growing and growing."
            />
            <Review
              key="review2"
              avatarURL="/images/testimonials/igor.png"
              discordTag="igor24#2770"
              review="I just stopped by to review this cook group a 5 star, I never cooked before, and with all the help being my first time, everyone was so patient and knowledgeable. Even though I didn’t cook up my first time I felt like I cooked up a lot of knowledge for me to continue trying. I want to thank @Owen @Kev for everything. I plan on staying with this cook group through thick and thin. I don’t want anything free, I just want to let y’all know I really appreciate everything. Knowledge is definitely power and I would recommend this cook group to anyone cooking up for their first time ! It was definitely a fun experience and I hope for the best with this cook group !"
            />
            <Review
              key="review3"
              avatarURL="/images/testimonials/Tee.png"
              discordTag="Tee#1010"
              review="Wanted to wait until I did something PHAT in here to leave a review, and I think hitting manual on Union Guava's constitutes as an absolute banger hit. I've been hanging around here since May, started off not talking at all then when the Off-White Jordan 4 Sails dropped I decided to pop into drop vc and had an incredible night full of laughs with Chef, Mike, and a muted Panda, never forget the great pulse oximeter loop. From there I started chatting more and more and offering assistance when I could and it has greatly increased the calue of the server for me.

I can't even begin to say how thankful I am for everyone here, especially Ape, Counter, Chef, Mike, Panda, Winston, Collin(Love the ELs), and all the others who have kept me entertained while I took Ls and celebrated with me when I took Ws. Love you all and thanks for creating a second home for me for the past 4 months with hopefully many more to come"
            />

            <Review
              key="review4"
              avatarURL="/images/testimonials/breeze.png"
              discordTag="Breeze#8216"
              review="I don't talk in here a whole lot, but this is easily one of the best groups I've been in. It just keeps getting better and better with each and every addition. If I'm going to be completely honest you guys are the most underrated cook group I've ever discovered.
Another thing I'd like to review is the family atmosphere here, again I don't talk a lot - which I should more often - but it's such a nice community that never disappoints me, it's so nice to see how much other people in this group help each other out.
Everything is conveniently placed so it's easy for me (and I'm sure other people) to access everything within seconds, very impressed by how this is all done and held together. Props to everyone keeping this going!
"
            />

            <Review
              key="review6"
              avatarURL="/images/testimonials/splice.png"
              discordTag="Spliiice#8095"
              review="Been here for 2 days and I can firmly say I'm staying loyal to Kraken. Love this group, everyone is so helpful and the monitors go off all day. Thanks for y'all "
            />

            <Review
              key="review9"
              avatarURL="/images/testimonials/KD.png"
              discordTag="kd#0095"
              review="10/10 Cook Group. Really friendly staff and friendly community! The guides and release info are amazing. 100% would recommend to anyone trying to get into reselling and trying to cook"
            />
            <Review
              key="review10"
              avatarURL="/images/testimonials/wishless.png"
              discordTag="Wishless#9145"
              review="Just wanted to stop by and review this group an 11/10. I am so grateful for the staff members and the information in here, it's really like no other group I've been in. I never felt what it was like to cook, but after cooking up 8 pairs of the Jordan 1 Bio Hacks with a bot rental I got (suggested by Counter), I can firmly say this group will be the one I stick with forever. I have easily made over 3k in here over the past 5 weeks with all the releases, especially with the past Kaws releases. I am so thankful for everything here, if you are looking for a long term group you would be a fool to not join Kraken. The amount of gratitude towards the staff members here and the other members helping each other out, I just want to sincerely thank you. Here's to many more cookouts."
            />
          </Carousel>
          <Image
            fluid={data.reviews.childImageSharp.fluid}
            className="lg:absolute lg:top-0 lg:right-0 lg:w-48 xl:w-64 lg:transform lg:translate-y-40 lg:-translate-x-12"
            style={{ position: "absolute" }}
            alt="Man holding phone looking at star reviews."
            draggable={false}
          />
        </div>
      </Section>

      {/* Pricing */}
      <Section>
        <div className="md:mb-16 lg:mb-24 xl:mb-32 md:space-y-16 mb-12 space-y-12">
          <div className="mb-3">
            <Heading>Pricing</Heading>
          </div>
          <Text tag="p" styleTo="h4" className="tracking-3px text-center">
            3 plans, at 3 different pricepoints
          </Text>
          <div className="md:mb-8 lg:mb-12 xl:w-10/12 xl:mx-auto md:space-y-0 md:flex md:justify-between md:items-center lg:p-12 rounded-xl p-6 space-y-8 bg-gray-900">
            <div className="md:flex md:items-center md:space-y-0 md:space-x-4 lg:space-x-6 xl:w-1/2 space-y-4">
              <div className="lg:w-12 lg:h-12 flex items-center justify-center w-10 h-10 bg-red-400 rounded-lg">
                <PaperPlane className="lg:w-6 lg:h-6 w-4 h-4" />
              </div>
              <div className="space-y-2">
                <Text styleTo="h3" tag="h3" className="tracking-3px">
                  Free
                </Text>
                <Text>100% free, with limited access</Text>
              </div>
            </div>
            <div className="xl:w-1/2">
              <a
                href="https://discord.gg/sneaker"
                target="_blank"
                rel="noopener noreferrer"
                className="focus:shadow-lg hover:shadow-lg focus:outline-none hover:-translate-y-1 focus:-translate-y-1 block w-full p-3 text-center transition duration-300 ease-out transform bg-red-400 rounded-lg"
              >
                <Text>Join now!</Text>
              </a>
            </div>
          </div>
          {/* Tables */}
          <div className="md:flex md:space-x-8 lg:space-x-12">
            <PriceTable
              Icon={Coins}
              name="Member"
              price={24.99}
              href="#"
              features={[
                "Kraken Mobile Access",
                "Kraken Autofill Access",
                "Kraken Companion Access",
                "Notification Hub Access",
                "Keyword Pinger Access",
                "Monitor access (300+ sites)",
                "Store Monitor Access (30+ sites)",
                "Checkout Log Monitors",
                "Twitter Monitors",
                "Full Release Information",
                "Release Stock Numbers",
                "Important Alerts",
                "Live Drop Updates",
                "Early Links",
                "Bot Restock Guides",
                "Bot Flips",
                "Bot Research Index",
                "Market Predictions",
                "Premium Guides",
                "Market Predictions",
                "Groupbuys",
                "Drop Recaps",
                "Bot Setups",
                "PC Setups",
                "Early SNKRS Info",
                "SNKRS Auto ATC",
                "1 on 1 Support System",
                "Premium SMS Notifications",
                "Community Mentoring Sessions",
                "Access to Partnered Providers",
                "US Lowkey Flips",
                "EU Lowkey Flips",
                "US Brick Flips",
                "EU Brick Flips",
                "Pokemon Flips",
                "Funko Flips",
                "Card Flips",
                "Sneaker Investments",
                "Card Flips",
                "Toy Flips",
                "Free Bot Rentals",
                "Free Slots",
                "Free Legit Checks",
                "Licks / Jigs",
              ]}
            />
            <PriceTable
              Icon={Stack}
              name="Premium"
              price={39.99}
              href="#"
              features={[
                "Free Daily Proxies",
                "Free Daily Servers",
                "Shoe Backdoors",
                "Supreme Backdoors",
                "Kraken Web Twitter",
                "Kraken Inventory Tracker",
                "Kraken Scripts",
                "Exclusive Giveaways",
                "Brick Buyback",
              ]}
              addition="All Member Perks"
            />
          </div>
        </div>
      </Section>

      {/* Platform */}
      <Section>
        <div className="md:w-10/12 md:mx-auto lg:w-full">
          <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
            <div className="mb-3">
              <Heading>Platform</Heading>
            </div>
            <Text tag="p" styleTo="h4" className="tracking-3px text-center">
              easy access, anywhere you are
            </Text>
          </div>
          {/* Notification Bar */}
          <div className="lg:flex lg:space-x-4 lg:items-center lg:justify-center p-6 mb-12 bg-gray-900 rounded-lg">
            <QuestionCircle className="lg:w-6 lg:h-6 lg:mb-0 w-10 h-10 mb-4" />
            <Text>
              Kraken Notify is hosted on{" "}
              <span className="text-discord font-bold">Discord</span>, which is
              a universal platform accessible everywhere
            </Text>
          </div>
          <div className="lg:flex lg:items-center lg:space-x-16">
            <div className="lg:w-1/2">
              {/* Announcement box */}
              <div className="relative p-6 pb-10 mb-24 bg-gray-900 rounded-lg">
                <Text className="lg:text-center">
                  Create a Discord account and join the best reselling community
                </Text>

                {/* Footer */}
                <div className="absolute inset-x-0 bottom-0 flex justify-center transform translate-y-8">
                  <a
                    href="https://discord.com/register"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="focus:shadow-lg hover:shadow-lg focus:outline-none hover:-translate-y-1 focus:-translate-y-1 block w-10/12 p-3 text-center transition duration-300 ease-out transform bg-red-400 rounded-lg"
                  >
                    <Text>Create a free account</Text>
                  </a>
                </div>
              </div>

              {/* Small Notification Bar */}
              <div className="p-3 mb-8 bg-gray-900 rounded-lg">
                <Text className="text-center">Downloads</Text>
              </div>

              <ul className="md:w-1/2 lg:w-80 flex justify-between w-10/12 mx-auto">
                <li>
                  <Download
                    icon={Windows}
                    url="https://discord.com/api/download?platform=win"
                  >
                    Windows
                  </Download>
                </li>
                <li>
                  <Download
                    icon={Apple}
                    url="https://discord.com/api/download?platform=osx"
                  >
                    OSX
                  </Download>
                </li>
                <li>
                  <Download
                    icon={Android}
                    url="https://play.google.com/store/apps/details?id=com.discord"
                  >
                    Android
                  </Download>
                </li>
                <li>
                  <Download
                    icon={iOS}
                    url="https://apps.apple.com/us/app/discord-chat-for-games/id985746746"
                  >
                    iOS
                  </Download>
                </li>
              </ul>
            </div>

            <div className="lg:w-1/2">
              <Image
                fluid={data.digitalNomad.childImageSharp.fluid}
                className="lg:w-10/12 lg:ml-auto lg:block hidden"
                draggable={false}
              />
            </div>
          </div>
        </div>
      </Section>

      {/* FAQ */}
      <Section id="faq">
        <div className="relative">
          <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
            <div className="mb-3">
              <Heading>FAQ</Heading>
            </div>
            <Text tag="p" styleTo="h4" className="tracking-3px text-center">
              some commonly asked{" "}
              <span className="text-red-500">questions</span>
            </Text>
          </div>

          <div className="md:w-10/12 md:mx-auto grid gap-12">
            <Fade
              direction="up"
              damping={0.1}
              cascade
              triggerOnce
              duration={500}
            >
              <FAQ
                icon={Certificate}
                question="What separates you from the rest?"
                answer="Unlike other groups, we prioritize member suggestions and experience. When we receive a suggestion that has enough interest, we do everything we can to have it implemented. Dissimilar to other groups, we do the hard work for you, not just provide information. This comes in various forms such as countless group buys, a 1-click support system, SMS notifications, and so much more. We are the ONLY group to provide a fully-custom and relevant ecosystem. With our custom dashboard, clean discord layout, bundles of custom tools and bots, resources, tips, tricks, and extensive guides, we can guarantee profit within the first month."
              />
              <FAQ
                icon={Robot}
                question="Do I need a bot to be able to cook with you?"
                answer="No! We have plenty of resources and tools to help you cook without the use of bots. We also 
provide FREE slots and FREE bots for our members to use on the hottest drops. Additionally,
we have our own custom tools (Companion and Autofill) that are FREE with your membership.  "
              />

              <FAQ
                icon={World}
                question="What countries are supported by Kraken?"
                answer="We mainly focus on providing information for Canada and the US, but we do support ALL 
regions around the world to better adhere to our customers needs!"
              />
              <FAQ
                icon={Question}
                question="What if I’m new to reselling?"
                answer="No problem! We have an excellent support team that will be patient and help you every step
of the way. We also have countless videos and guides to ensure your you are prepared to cook 
every release."
              />
              <FAQ
                icon={Dollar}
                question="Do you guys sell lifetime memberships?"
                answer="Unfortunately we don’t sell lifetime memberships. The only way to acquire a copy is by winning 
a giveaway in the discord or on twitter."
              />
            </Fade>
          </div>

          <Image
            fluid={data.questionsBro.childImageSharp.fluid}
            style={{ position: "absolute" }}
            alt="Person thinking with questions marks floating around"
            className="lg:absolute lg:w-48 lg:block lg:transform lg:translate-x-6 lg:translate-y-6 top-0 left-0 hidden"
            draggable={false}
          />
        </div>
      </Section>

      {/* Socials */}
      <Section>
        <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
          <div className="mb-3">
            <Heading>Socials</Heading>
          </div>
          <Text tag="p" styleTo="h4" className="tracking-3px text-center">
            drop a follow, maybe?
          </Text>
        </div>

        <div className="md:flex md:items-center md:space-x-12 md:justify-center">
          <div className="md:block md:w-1/2 lg:w-5/12 hidden">
            <Image
              fluid={data.socialMedia.childImageSharp.fluid}
              alt="social media network with people and providers connecting to each other"
              draggable={false}
            />
          </div>
          <div className="md:w-1/2 lg:w-4/12 lg:gap-16 grid gap-8">
            <Fade
              direction="up"
              damping={0.1}
              cascade
              triggerOnce
              duration={500}
            >
              <SocialMedia
                gradient={["#59ADEC", "#59ADEC", "#59ADEC"]}
                url="https://twitter.com/krakennotify"
                icon={Twitter}
              >
                @krakennotify
              </SocialMedia>
              <div className="lg:transform lg:translate-x-12">
                <SocialMedia
                  gradient={["#FDB430", "#F2473B", "#A128A6"]}
                  url="https://instagram.com/krakennotify"
                  icon={Instagram}
                >
                  @krakennotify
                </SocialMedia>
              </div>
            </Fade>
          </div>
        </div>
      </Section>

      {/* Contact */}
      <Section>
        <div className="md:mb-16 lg:mb-24 xl:mb-32 mb-12">
          <div className="mb-3">
            <Heading>Contact</Heading>
          </div>
          <Text tag="p" styleTo="h4" className="tracking-3px text-center">
            get in touch, <span className="text-red-500">anytime</span>
          </Text>
        </div>
        <div className="lg:flex lg:items-center lg:justify-center lg:space-x-16 xl:space-x-32">
          <div className="lg:w-1/2">
            <ContactForm />
          </div>
          <Image
            fluid={data.contact.childImageSharp.fluid}
            alt="Girl and boy leaning against a large phone and texting."
            className="lg:w-1/2 lg:block hidden"
            draggable={false}
          />
        </div>
      </Section>
    </Layout>
  )
}

export default Index
