import React from "react"

// Component imports
import Text from "../components/Text"

interface Props {
  title: string
  Icon: React.ReactNode
  description: string
}

const Feature = ({ title, Icon, description }: Props) => {
  return (
    <div
      tabIndex={0}
      className="group focus:outline-none hover:shadow-xl lg:h-80 focus:shadow-xl relative z-10 flex flex-col justify-center px-4 py-12 mx-4 transition duration-300 ease-out origin-bottom transform bg-gray-900 border border-red-400 rounded-lg cursor-pointer"
    >
      <Icon className="group-focus:opacity-0 group-hover:opacity-0 w-16 h-16 mb-6 transition-all duration-300 ease-out" />
      <Text
        tag="h3"
        styleTo="h4"
        className="group-hover:-translate-y-24 group-focus:-translate-y-24 relative z-10 uppercase transition duration-300 ease-out delay-100 transform"
      >
        {title.split(" ").map((word: string, i: number) => (
          <span
            key={i}
            className={`${
              i === 0
                ? "text-red-400 block group-focus:text-white group-hover:text-white transition duration-300 ease-out"
                : ""
            }`}
          >
            {word}
            {i !== 0 ? " " : ""}
          </span>
        ))}
      </Text>

      {/* Overlay */}
      <div className="group-hover:h-full group-focus:h-full absolute inset-x-0 bottom-0 h-0 overflow-hidden transition-all duration-300 ease-out delay-75 bg-red-400 rounded-lg">
        <div className="pt-28 md:pt-32 lg:pt-44 flex h-full p-4">
          <Text className="group-hover:opacity-100 group-focus:opacity-100 xl:text-sm transition duration-300 ease-out delay-75 opacity-0">
            {description}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default Feature
